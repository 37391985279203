.text-line {
  color: #b4b4b4;
  text-align: "center";
  font-size: 72px;
}
/* Home.css */

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-50px); /* Başlık yukarıdan gelsin */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Yerine otursun */
  }
}

@keyframes slideIn {
  0% {
    width: 0;
    left: 0;
  }
  100% {
    width: 100%;
    left: 0;
  }
}

/* Başlık animasyonu */
.text-line {
  animation: fadeIn 1s ease-out forwards; /* 1 saniye içinde animasyon tamamlanır */
}

/* Span içinde yer alan turuncu arka plan */
.text-line span span {
  animation: slideIn 1.2s ease-out forwards; /* 2 saniye içinde soldan sağa kayar */
  position: absolute;
}

.carousel-item-custom {
  height: 80vh; /* Başlangıç yüksekliği */
}

.carousel-img {
  height: 100%;
  object-fit: cover;
}

@media (max-width: 992px) {
  .carousel-item-custom {
    height: 60vh; /* Orta ekranlarda daha küçük yükseklik */
  }
}

@media (max-width: 576px) {
  .carousel-item-custom {
    height: 40vh; /* Küçük ekranlarda daha da küçük yükseklik */
  }
}

.hero-section {
  position: relative;
  height: 90vh;
  min-height: 600px;
  overflow: hidden;
}

.carousel-image-container {
  width: 100%;
  height: 90vh;
  min-height: 600px;
  position: relative;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.9);
}

.carousel-item.active .carousel-image {
  transform: scale(1);
}

.custom-caption {
  background: none;
  padding: 0 1rem;
  text-align: left;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 2;
}

.custom-caption::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(23, 23, 23, 0.2);
  backdrop-filter: blur(2px);
  z-index: -1;
}

.custom-caption::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.4) 0%, transparent 100%);
  z-index: -1;
}

.hero-title {
  font-size: clamp(2.5rem, 6vw, 4.5rem);
  font-weight: 700;
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  line-height: 1.1;
  padding-left: 10%;
}

.hero-subtitle {
  font-size: clamp(1.1rem, 2.5vw, 1.8rem);
  margin-bottom: clamp(1.5rem, 3vw, 2.5rem);
  color: rgba(255, 255, 255, 0.95);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  line-height: 1.4;
  padding-left: 10%;
}

.hero-button {
  margin-left: 10% !important;
  padding: 12px 32px !important;
  font-size: 1.1rem !important;
  text-transform: none !important;
  border-width: 2px !important;
  border-radius: 4px !important;
  transition: all 0.3s ease !important;
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.carousel-indicators {
  margin-bottom: 3rem;
  z-index: 2;
  margin-left: 10%;
  margin-right: auto;
  justify-content: flex-start;
}

.carousel-indicators button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  margin: 0 6px !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
  border: none !important;
  transition: all 0.3s ease;
}

.carousel-indicators .active {
  background-color: #ffffff !important;
  transform: scale(1.2);
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hero-section:hover .carousel-control-prev,
.hero-section:hover .carousel-control-next {
  opacity: 0.8;
}

/* Mobil Responsive Ayarlar */
@media (max-width: 768px) {
  .hero-section,
  .carousel-image-container {
    height: 80vh;
    min-height: 500px;
  }

  .custom-caption {
    text-align: center;
    justify-content: center;
    padding: 0 20px;
  }

  .custom-caption::before {
    background: rgba(23, 23, 23, 0.3);
    backdrop-filter: blur(3px);
  }

  .custom-caption::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, transparent 100%);
  }

  .hero-title,
  .hero-subtitle,
  .hero-button {
    padding-left: 0 !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
  }

  .hero-title {
    font-size: clamp(2rem, 8vw, 3rem);
    margin-bottom: 1rem;
  }

  .hero-subtitle {
    font-size: clamp(1rem, 4vw, 1.4rem);
    margin-bottom: 2rem;
  }

  .carousel-indicators {
    margin-left: auto;
    justify-content: center;
    margin-bottom: 2rem;
  }
}

@media (max-width: 480px) {
  .hero-section,
  .carousel-image-container {
    height: 70vh;
    min-height: 400px;
  }

  .custom-caption {
    padding: 0 15px;
  }

  .hero-button {
    padding: 10px 24px !important;
    font-size: 1rem !important;
  }
}

.simple-hero {
  position: relative;
  height: 85vh;
  min-height: 600px;
  overflow: hidden;
  background-color: #000;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.4) 100%
  );
  z-index: 1;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.9;
}

.hero-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  z-index: 2;
  padding: 0 20px;
}

.main-title {
  font-size: clamp(2.5rem, 5vw, 4rem) !important;
  font-weight: 800 !important;
  margin-bottom: 1rem !important;
  letter-spacing: -0.5px !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5) !important;
  color: #ffffff !important;
}

.sub-title {
  font-size: clamp(1.2rem, 2.5vw, 1.8rem) !important;
  font-weight: 500 !important;
  margin-bottom: 2rem !important;
  opacity: 1 !important;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5) !important;
  color: #ffffff !important;
}

.main-button {
  padding: 12px 28px !important;
  font-size: 1rem !important;
  text-transform: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  letter-spacing: 0.3px !important;
}

@media (max-width: 768px) {
  .hero-background::after {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }

  .hero-content {
    text-align: center;
    width: 100%;
    padding: 0 30px;
  }

  .main-button {
    padding: 10px 24px !important;
    margin: 5px !important;
    min-width: 200px !important;
  }
}

@media (max-width: 480px) {
  .main-title {
    font-size: clamp(2rem, 8vw, 2.5rem) !important;
    line-height: 1.2 !important;
  }

  .sub-title {
    font-size: clamp(1rem, 4vw, 1.2rem) !important;
    line-height: 1.4 !important;
  }

  .hero-content {
    padding: 0 20px;
  }
}
