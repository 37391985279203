.container {
  background-color: #262626;
}
.img-uretim {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.urun-container {
  padding-top: 20%;

  margin-left: 20%;
}
.urun-header {
  color: white;
  letter-spacing: 0.05em;
  font-size: 40px;
  font-family: raleway, sans-serif;
}
.urun-list a {
  color: white; /* Yazı rengi beyaz */
}

.urun-list a:hover {
  color: white; /* Hover durumunda da beyaz kalmasını sağla */
}
.urun-list li {
  padding-bottom: 10px;
}
.urun-button {
  background-color: #9a8178;
  border: none;
  font-size: 20px;
  padding: 10px 80px;
}
.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease;
}
.icon-container:hover {
  transform: translateY(-5px);
}
.icon-text {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: white;
  line-height: 1.4;
}
@media (max-width: 600px) {
  .icon-text {
    font-size: 1rem;
  }
}
/* Animasyon Stilleri */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.animate-fade-in {
  animation: fadeIn 0.8s ease-out forwards;
}
.urun-subheader {
  color: #9a8178;
  font-family: raleway, sans-serif;
  font-size: 30px;
}
