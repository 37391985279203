.hero-image {
  height: 100%; /* Kart yüksekliğinin tamamını kaplasın */
  width: 100%; /* Kart genişliğinin tamamını kaplasın */
  object-fit: cover; /* Görseli kırpmadan ortalayarak genişlet */
  filter: brightness(1); /* Parlaklık efekti */
}

@media (max-width: 768px) {
  .hero-card {
    height: 30vh; /* Daha küçük ekranlarda hero yüksekliğini azaltın */
  }
}
